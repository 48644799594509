// import {TethysAppName} from "../config";


let BaseUrl = null;
let hostName = window.location.hostname;
if (process.env.NODE_ENV === "production") {
    // BaseUrl = "http://localhost:8000";

    // BaseUrl = 'http://smog.spatialapps.net';
    BaseUrl = 'http://' + hostName;

} else {
    // BaseUrl = "http://" + '192.168.10.211' + ":9001";
    BaseUrl = 'http://' + '192.168.12.26' + ':8001';
    // BaseUrl = "http://"+hostName+":8000";
}

export let Action = {

    Base: BaseUrl,
    GeoServerBase: 'http://smog.spatialapps.net:8080/geoserver',

    // Base : 'http://110.44.114.244:8001/',
    CityData: "apps/airqualitynp/getCityData",
    Aeronet: 'apps/airqualitynp/aeronetaodpm',
    USEmbassyAOD: 'apps/airqualitynp/usembassypm',
    getGeoJSONofStations: 'apps/airqualitynp/getGeoJSONofStations',
    getGeoJsonForOneSatation: 'apps/airqualitynp/getGeoJsonForOneSatation',
    getAllStationsID: 'apps/airqualitynp/getAllStationsID',

    commonAPI: 'apps/airqualitynp/getData/',
    RegionGeojson: 'apps/airqualitynp/geojsonregion',
    AOIPolygon: 'apps/airqualitynp/aoipolygon',
    GetMapImage: 'apps/airqualitynp/getmapimage/',
    GetImage: 'apps/airqualitynp/downloadImage',
    SlicedFromCatalog: 'apps/airqualitynp/slicedfromcatalog/',
    SliceFromCatalogForecast: 'apps/airqualitynp/SliceFromCatalogForecast/',
    TimeSeriesRasterQuery: 'apps/airqualitynp/timeseriesmodeldata/',
    trueColorLegendImage: 'apps/airqualitynp/trueColorLegendImage/',
    defaultObservationStation: 'apps/airqualitynp/defaultobservationstation/',

    //emission
    CascaderOptions: 'apps/airqualitynp/getCascaderData/',
    ChartDataProcess: 'apps/airqualitynp/getChartDataProcess/',
    LayerInfoStat: 'apps/airqualitynp/getLayerInfoStat/',

};
